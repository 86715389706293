import Footer from "../../component/Footer/footer"
import Header from "../../component/Header/header"

const Contactus=()=>{
    return(<>
    <Header></Header>
    <p>contact us</p>
    <Footer></Footer>
    
    </>)
}

export default Contactus