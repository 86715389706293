import Footer from "../../component/Footer/footer"
import Header from "../../component/Header/header"

const HelpSupport=()=>{
    return(<>
    <Header></Header>
    <p>Help & Support</p>
    <Footer></Footer>
    
    </>)
}

export default HelpSupport