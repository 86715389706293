const Propertycards = () => {
    return (<>
        <section className="section-gap-md">
            <div className="container">
                <div className="section-title text-center">
                    <h2>Explore All Things Property</h2>
                    <p>With market leading insights from proptrail, use your property dashboard to stay on top of:</p>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="propbox">
                            <div className="propboxMedia"><img src="/img/home.png" alt='home'></img></div>
                            <div className="propboxContent">
                                <h2>How much your property's worth</h2>
                                <p className="mb-0">Proptrail can provide your estimated property value in less than a minute.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="propbox">
                            <div className="propboxMedia"><img src="/img/chart.png" alt='chart'></img></div>
                            <div className="propboxContent">
                                <h2>Latest insights and data on the property market</h2>
                                <p className="mb-0">Monitor the market to make informed property decisions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="propbox">
                            <div className="propboxMedia"><img src="/img/sold.png" alt='sold'></img></div>
                            <div className="propboxContent">
                                <h2>What homes like yours are selling for</h2>
                                <p className="mb-0">Check out recently sold and currently listed properties in your area.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="propbox">
                            <div className="propboxMedia"><img src="/img/coin.png" alt='coin'></img></div>
                            <div className="propboxContent">
                                <h2>Whether your home loan's competitive</h2>
                                <p className="mb-0">Track your loan to receive updates for better home loan rates and equity.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </>)
}

export default Propertycards