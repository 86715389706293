import SweetAlert from "react-bootstrap-sweetalert";
import React, { useState } from "react";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useNavigate } from "react-router-dom";

const Account=()=>{
    const [show, setShow] = useState(false);
    const navigate =useNavigate()
    const logOut = () => {
        setShow(true);
      };
    
      const Confirm = () => {
        localStorage.removeItem("USER_TOKEN")
        navigate("/");
        window.location.reload()
      };
    
      const Cancelalert = () => {
        setShow(false);
      };
    
    return(<>
    <Header></Header>
    <section className="section-gap-md">
    <div className="container">
        <div className="row">
        <button className="btn btn-primary w-25" onClick={()=>{logOut()}}>Logout</button>
        </div>
        <SweetAlert
        warning
        confirmBtnCssClass="alertpop"
        title={` Are you sure you want to Logout ? `}
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        show={show}
        onConfirm={Confirm}
        onCancel={Cancelalert}
        btnSize="md"
        showCancel
        cancelBtnBsStyle="danger"
      ></SweetAlert>

    </div>
                </section>
   
 <Footer></Footer>
    
    </>)
}

export default Account