
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../component/Services/apiservices";
import moment from "moment";
import { Chart } from "react-google-charts"
import Loginmodal from "../../component/modals/login_modal";
import { toast } from "react-toastify";
import SpinnerLoader from "../../component/utils/loader";


function PurchaseSummary() {
    let apiServices = new ApiService()
    const didMountRef = useRef(true)
    const [propertyData, setPropertyData] = useState("")
    const [maintainncetableDataArra, setmaintainncetableDataArra] = useState([]);
    const maintainncetableDataArraRef = useRef(maintainncetableDataArra);
    const [showlogin, setshowlogin] = useState(false)
    const [showloader, setshowloader] = useState(false)
    const [showspinnloader, setshowspinnloader] = useState(false)
    const [shownxtbtn, setshownxtbtn] = useState(false)
    const [values, setvalues] = useState({ conditionsec: 0, conditionthrd: 0, unconditthrd: 0 })
    const { id } = useParams()
    const navigate = useNavigate()
    useEffect(() => {

        if (didMountRef.current) {
            const dataString = {
                id: id
            }
            apiServices.getpropertydataPostRequest(dataString).then(res => {
                if (res.data.status == "success") {
                    setPropertyData(res.data)

                    setvalues(prevValues => ({
                        ...prevValues,
                        deposit: parseFloat(res.data?.data?.property_deposit),
                    }));
                    setvalues(prevValues => ({
                        ...prevValues,
                        conditionsec: parseFloat(res.data?.purschasedata?.purchase_condit_secamount),
                    }));
                    setvalues(prevValues => ({
                        ...prevValues,
                        conditionthrd: parseFloat(res.data?.purschasedata?.purchase_condit_thrdamount),
                    }));
                    setvalues(prevValues => ({
                        ...prevValues,
                        unconditthrd: parseFloat(res.data?.purschasedata?.purchase_uncondit_thrdamount),
                    }));
                }
                else{

                }
            }).catch((errors)=>{})
            const usertoken = localStorage.getItem("USER_TOKEN")
            if (usertoken !== null && usertoken!==undefined) {
                setshownxtbtn(true)
            }
            else {
                setshownxtbtn(false)
            }
            fetchemaninatincedata()
        }
        didMountRef.current = false;
    })

   
    useEffect(() => {
        maintainncetableDataArraRef.current = maintainncetableDataArra;
    }, [maintainncetableDataArra]);
    const handleClose = () => {
        setshowlogin(false)
    }
    const purchasedata = [
        [
            "Element",
            "Amount needed",
            { role: "style" },
            {
                sourceColumn: 0,
                role: "annotation",
                type: "string",
                calc: "stringify",
            },
        ],
        [moment(propertyData?.purschasedata?.purchase_contract_date).format(`DD-MM-YYYY`), values.deposit, "#b87333", null],
        [propertyData?.purschasedata?.purchase_conditional_date !== null ? moment(propertyData?.purschasedata?.purchase_conditional_date).format(`DD-MM-YYYY`) : propertyData?.purschasedata?.purchase_unconitional_date !== null ? moment(propertyData?.purschasedata?.purchase_unconitional_date).format(`DD-MM-YYYY`) : new Date(), propertyData?.purschasedata?.purchase_conditional_date !== null ? values.conditionsec : propertyData?.purschasedata?.purchase_conditional_date !== null ? 0 : 0, "#b87333", null],
        [moment(propertyData?.purschasedata?.purchase_settelment_date).format(`DD-MM-YYYY`), propertyData?.purschasedata?.purchase_conditional_date !== null ? values.conditionthrd : propertyData?.purschasedata?.purchase_unconitional_date !== null ? values.unconditthrd : 0, "#b87333", null],
        // [values.settlementdate, values.conditionthrd, "#b87333", null],


    ];

    const purchaseoptions = {

        width: 600,
        height: 400,
        bar: { groupWidth: "60%" },
        legend: { position: "none" },
        orientation: 'horizontal', // Set orientation to horizontal
    };
    const handleContinueClick = () => {
        let getUserToken = localStorage.getItem("USER_TOKEN")
        if (getUserToken == null) {
            setshowlogin(true)
        }
        else {
            setshowlogin(false)
        }
    }
    const fetchemaninatincedata = async () => {
        setshowspinnloader(true)
        const dataString = {
            id: id
        };
        try {
            const res = await apiServices.getmainatinancetablePostRequest(dataString);
            if (res.data.status === "success") {
                setmaintainncetableDataArra(res?.data?.maintainaancedata)
                setshowspinnloader(false)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setshowspinnloader(false)
        }
    };

    const ONnxtclick = () => {
        setshowloader(true)
        setTimeout(() => {
            if (maintainncetableDataArraRef.current?.length > 0) {
                // const userdata = JSON.parse(localStorage.getItem("USER_TOKEN"));
                const dataString = {
                    // user_email : userdata?.user_email,
                    maintainance_table_data: maintainncetableDataArraRef.current,
                    property_id: id,
                    type: 0
                }
                apiServices.savenewmainatinancetablePostRequest(dataString).then((res) => {
                    if (res?.data?.status == "success") {
                        toast.success(res?.data?.message)
                        setshowloader(false)
                        navigate(`/maintainance/${id}`)
                    }
                    else if (res?.data?.status == "error") {
                        toast.error(res?.data?.message)
                        // localStorage.removeItem("USER_TOKEN")
                        setshowloader(false)
                        // navigate(`/purchase-summary/${id}`)
                    }

                })
            }
        }, [6000])
    }
    function generateGraphOptions(title) {
        return {
            hAxis: {
                title: "year",
                gridlines: {
                    count: 1,
                },
            },
            vAxis: {
                title: title,
            },
            chartArea: { width: "50%", height: "70%" },
            series: {},
        };
    }
    const performancedata = [
        ["x", "total performance inc princi", "capital Growth (Cummulative)", "Net annual cashflow (cummulative)", "total principal payment (cummulative)"],
        ...(maintainncetableDataArra?.map((items, index) => [
            items?.currentYear,
            (items?.total_performance_principal),
            (items?.capital_growth_annual_cummulative),
            (items?.net_annual_cashflow_cummulative),
            (items?.totalprincipalpaymentcummulative),

        ])),
    ];

    return (
        <>

            <Header />
            <div className="container">
          
                <div style={{ width: "380px", backgroundColor: "#ddf8dd" }}>
                    <h6 style={{ backgroundColor: "rgb(96 237 96)" }}>Purchase Summary</h6>
                    <div className="px-5" >
                        <p>
                            John Doe is purchasing some random address {propertyData?.data?.property_adress} for the price of ${parseFloat(propertyData?.data?.property_purchase_price)} and where bank is evaluated it at ${parseFloat(propertyData?.data?.property_bank_valuation)} and providing a loan amount of ${parseFloat(propertyData?.data?.property_loan_amount)}. You would need total ${parseFloat(propertyData?.purschasedata?.purchase_total_cash_req)} for the deal which is to be paid on 3 date as following:
                        </p>
                    </div>
                </div>
                <div>
                    <table>
                        {propertyData?.purschasedata?.purchase_conditional_date !== null ? <>

                            <tr>
                                <th>Date</th>
                                <th>Amount Needed</th>
                            </tr>
                            <tr>
                                <td>{moment(propertyData?.purschasedata?.purchase_contract_date).format(`DD-MM-YYYY`)}</td>
                                <td>{(propertyData?.data?.property_purchase_deposit)?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>{propertyData?.purschasedata?.purchase_conditional_date ? moment(propertyData?.purschasedata?.purchase_conditional_date).format(`DD-MM-YYYY`) : ""}</td>
                                <td>{propertyData?.purschasedata?.purchase_condit_secamount}</td>
                            </tr>
                            <tr>
                                <td>{propertyData?.purschasedata?.purchase_settelment_date ? moment(propertyData?.purschasedata?.purchase_settelment_date).format(`DD-MM-YYYY`) : ""}</td>
                                <td>{propertyData?.purschasedata?.purchase_condit_thrdamount}</td>
                            </tr>



                        </> : <>


                            {propertyData?.purschasedata?.purchase_unconitional_date !== null && <>
                                <tr>
                                    <th>Date</th>
                                    <th>Amount Needed</th>
                                </tr>
                                <tr>
                                    <td>{moment(propertyData?.purschasedata?.purchase_contract_date).format(`DD-MM-YYYY`)}</td>
                                    <td>{(propertyData?.data?.property_purchase_deposit)?.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>{propertyData?.purschasedata?.purchase_unconitional_date ? moment(propertyData?.purschasedata?.purchase_unconitional_date).format(`DD-MM-YYYY`) : ""}</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td>{propertyData?.purschasedata?.purchase_settelment_date ? moment(propertyData?.purschasedata?.purchase_settelment_date).format(`DD-MM-YYYY`) : ""}</td>
                                    <td>{propertyData?.purschasedata?.purchase_uncondit_thrdamount}</td>
                                </tr>
                            </>}
                        </>}
                    </table>
                </div>
                {showspinnloader && <SpinnerLoader />}
                <div>
                    <Chart
                        chartType="BarChart"
                        width="100%"
                        height="400px"
                        data={purchasedata}
                        options={purchaseoptions}
                    />

                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="400px"
                        data={performancedata}
                        options={generateGraphOptions('Total Performance')}
                    />

                </div>
                {!shownxtbtn && <div>
                    <button type="button" className="btn btn-primary" onClick={handleContinueClick} disabled={showspinnloader}
                    >
                        {showloader ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                            "Continue"
                        )}</button>
                </div>}

                {shownxtbtn && <div>
                    <button type="button" className="btn btn-primary" onClick={ONnxtclick} disabled={showloader}
                    >
                        {showloader ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                            "Next"
                        )}</button>
                </div>}


            </div>
            <Footer />
            {showlogin && <Loginmodal showlogin={showlogin} handleClose={handleClose} setshowlogin={setshowlogin} id={id}></Loginmodal>}
        </>
    )
}
export default PurchaseSummary;