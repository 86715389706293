import Footer from "../../component/Footer/footer"
import Header from "../../component/Header/header"

const Pages=()=>{
    return(<>
    <Header></Header>
    <h1></h1>
    <Footer></Footer>
    </>)
}

export default Pages