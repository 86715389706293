import React, { useState, useEffect } from "react";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { useParams } from "react-router-dom";
let apiServices = new ApiService()
const MaintainanceDetail = () => {
    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors }, 
        getValues ,
        setValue
    } = useForm();

    useEffect(() => {
   const rentalpwvalue = getValues('rentalpw')
            setValue('grossannualrentalincome', rentalpwvalue);

        
      }, [getValues, setValue]);
const {id} = useParams()
      const rentalpwfunction=()=>{
        setValue('grossannualrentalincome', getValues('rentalpw')*52);
        setValue('ownerrentsaved', getValues('rentalpw')*getValues('owneroccupiedweeks'));
        setValue('vacancyrate', getValues('vacantweeks')/52);
        setValue('rentalloss', getValues('rentalpw')*getValues('vacantweeks'));
        setValue('lettingfee', getValues('grossannualrentalincome')*getValues('lettingfeeweeks'));
        setValue('totalpropertymanagementfee',getValues('grossannualrentalincome')*getValues('propertymanagementfeepercentage'));
        setValue('forcastannualcashexpense', parseInt((getValues('buildinginsurancefee'))) +parseInt(getValues('waterrates'))+ parseInt(getValues('councilrates'))+ parseInt(getValues('yardmaintenance'))+ parseInt(getValues('maintainanceallownace')));
        setValue('potentialdeductions', parseInt((getValues('forecastannualnondeductexpense'))) +parseInt(getValues('interestfixed'))+ parseInt(getValues('interestvariable')));
        setValue('equity', parseInt((getValues('propertyvalue'))) - parseInt(getValues('totalloanamount')));
      }
      
    const onSubmit = (data) => { 
        const dataString={
            maintainance_year:data.year,
            maintainance_rental_pw:data.rentalpw,
            maintainance_gross_annual_rental_income:data.grossannualrentalincome,
          maintainance_owner_occupied_weeks:data.owneroccupiedweeks,
          maintainance_owner_rent_saved:data.ownerrentsaved,
          maintainance_vacant_weeks:data.vacantweeks,
          maintainance_vacancy_rate:data.vacancyrate,
          maintainance_rental_loss:data.rentalloss,
          maintainance_actual_rental_income:data.actualrentalincome,
          maintainance__letting_fee_weeks:data.lettingfeeweeks,
          maintainance_letting_fee:data.lettingfee,
          maintainance_property_managment_fees_percentage:data.propertymanagementfeepercentage,
          maintainance_total_property_managment_fee:data.totalpropertymanagementfee,
          maintainance_loan_amount:data.loanamount,
          maintainance_bank_name:data.purchasebank,
          maintainance_loan_type:data.loantype,
          maintainance_interest_fixed:data.interestfixed,
          maintainance_percentage_fixed:data.percentagefixed,
          maintainance_term_fixed:data.termfixed,
          maintainance_interest_variable:data.interestvariable,
          maintainance_percentage_variable:data.percentagevariable,
          maintainance_term_variable:data.termvariable,
          maintainance_building_insurance_fee:data.buildinginsurancefee,
          maintainance_water_rates:data.waterrates,
          maintainance_council_rates:data.councilrates,
          maintainance_yard_maintainance:data.yardmaintenance,
          maintainance_maintainance_allownace:data.maintainanceallownace,
          maintainance_land_tax:data.landtax,
          maintainance_principal_payment:data.principalpayment,
          maintainance_Forcast_annual_non_deduct_expense:data.forecastannualnondeductexpense,
          maintainance_potential_deduction:data.potentialdeductions,
          maintainance_potential_annual_net_cash_flow:data.potentialann,
          maintainance_property_value:data.propertyvalue,
          maintainance_fixed_loan_amount:data.fixedloanamount,
          maintainance_variable_amount:data.variableloanamount,
          maintainance_total_loan_amount:data.totalloanamount,
          maintainance_equity:data.equity,
          maintainance_fixed_principal_amount:data.fixedprincipalpayment,
          maintainance_fixed_principal_payment_cumulative:data.fixedprincipalpaymentcumulative,
          maintainance_variable_principal_payment :data.variableprincipalpayment,
          maintainance_Forcast_annual_cash_expense:data.forcastannualcashexpense,
          maintainance_variable_principal_payment_cumulative:data.variableprincipalpaymentcumulative,
          maintainance_total_principal_payment:data.totalprincipalpaymentcumulative,
          maintainance_total_principal_payment_cumulative:data.totalprincipalpaymentcumulative,
          maintainance_gross_yield:data.grossyield,
          maintainance_cash_flows:data.cashoutflows,
          maintainance_total_interest_expense:data.totalinterestexpense,
          maintainance_net_annual_cashflow:data.netannualcashflow,
          maintainance_Net_annual_cashflow_cummulative:data.netannualcashflowcumulative,
          maintainance_net_yield:data.netyield,
          maintainance_income_per_month:data.incomepermonth,
          maintainance_income_per_week:data.incomeperweek,
          maintainance_capital_growth:data.capitalgrowth,
          maintainance_capital_growth_cumulative:data.capitalgrowthcumulative,
          maintainance_total_performance:data.totalperformance,
          maintainance_total_performance_principal:data.totalperformanceprincipal,
          maintainance_cash_on_cash_return_cumulative:data.cashoncashreturncumulative,
          maintainance_return_invested_capital:data.returnoninvestedcapital,
          maintainance_renovation_cost:data.renovationcost,
          maintainance_renovation_equity_uplift:data.renovationequityuplift,
          maintainance_renovation_rental_uplift:data.grannyflatrentaluplift,
          maintainance_sub_division_cost:data.subdivisioncost,
          maintainance_sub_division_equity_uplift:data.subdivisionequityuplift,
          maintainance_subdivision_rental_uplift:data.subdivisionrentaluplift,
          maintainance_granny_flat_cost:data.grannyflatcost,
          maintainance_granny_flat_equity_uplift:data.grannyflatrentaluplift,
          maintainance_granny_flat_rental_uplift_fee:data.grannyflatrentaluplift,
          maintainance_total_uplift_cost:data.totalupliftcost,
          maintainance_total_equity_uplift:data.totalequityuplift,
          maintainance_total_rental_uplift:data.totalrentaluplift,
          maintainance_price_growth:data.pricegrowth,
          maintainance_rental_growth:data.rentalgrowth,
          maintainance_inflation:data.inflation,
          maintainance_property_id: id
        }

        apiServices.savemaintainancedataPostRequest(dataString).then(res=>{

        })

    };
    const advanceddetailcheckbox = watch("advanceddetails")
    return (<>
        <Header />
        <div className="container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Year</label>
                            <input
                                type="number"
                                class="form-control"
                                name="year"
                                {...register("year", {
                                    required: true,
                                })}
                            />
                            {errors.year && errors.year.type === "required" && (
                                <small className="text-danger">Year is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Rental pw</label>
                            <input
                            onKeyUp={rentalpwfunction}
                                type="number"
                                class="form-control"
                                name="rentalpw"
                                {...register("rentalpw", {
                                    required: true,
                                })}
                            />
                            {errors.rentalpw && errors.rentalpw.type === "required" && (
                                <small className="text-danger">Rental pw is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Gross Annual Rental Income</label>
                            <input
                                type="number"
                                class="form-control"
                                name="grossannualrentalincome"
                               onKeyUp={rentalpwfunction}
                                {...register("grossannualrentalincome", {
                                    required: true,
                                })}
                            />
                            {errors.grossannualrentalincome && errors.grossannualrentalincome.type === "required" && (
                                <small className="text-danger">Gross Annual Rental Income is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Owner Occupied Weeks</label>
                            <input
                               onKeyUp={rentalpwfunction}
                                type="number"
                                class="form-control"
                                name="owneroccupiedweeks"
                                {...register("owneroccupiedweeks", {
                                    required: true,
                                })}
                            />
                            {errors.owneroccupiedweeks && errors.owneroccupiedweeks.type === "required" && (
                                <small className="text-danger">Owner Occupied Weeks is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Owner Rent Saved</label>
                            <input
                                type="text"
                                class="form-control"
                                name="ownerrentsaved"
                                {...register("ownerrentsaved", {
                                    required: true,
                                })}
                            />
                            {errors.ownerrentsaved && errors.ownerrentsaved.type === "required" && (
                                <small className="text-danger">Owner Rent Saved is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Vacant Weeks</label>
                            <input
                                type="text"
                                class="form-control"
                                name="vacantweeks"
                                {...register("vacantweeks", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                            />
                            {errors.vacantweeks && errors.vacantweeks.type === "required" && (
                                <small className="text-danger">Vacant Weeks is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Vacancy Rate</label>
                            <input
                                type="text"
                                class="form-control"
                                name="vacancyrate"
                                {...register("vacancyrate", {
                                    required: true,
                                })}
                                
                            />
                            {errors.vacancyrate && errors.vacancyrate.type === "required" && (
                                <small className="text-danger">Vacancy Rate is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Rental Loss</label>
                            <input
                                type="text"
                                class="form-control"
                                name="rentalloss"
                                {...register("rentalloss", {
                                    required: true,
                                })}
                            />
                            {errors.rentalloss && errors.rentalloss.type === "required" && (
                                <small className="text-danger">Rental Loss is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Actual Rental Income</label>
                            <input
                                type="text"
                                class="form-control"
                                name="actualrentalincome"
                                {...register("actualrentalincome", {
                                    required: true,
                                })}
                            />
                            {errors.actualrentalincome && errors.actualrentalincome.type === "required" && (
                                <small className="text-danger">Actual Rental Income is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Letting Fee Weeks</label>
                            <input
                                type="number"
                                class="form-control"
                                name="lettingfeeweeks"
                                {...register("lettingfeeweeks", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                            />
                            {errors.lettingfeeweeks && errors.lettingfeeweeks.type === "required" && (
                                <small className="text-danger">Letting Fee Weeks is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Letting Fee</label>
                            <input
                                type="number"
                                class="form-control"
                                name="lettingfee"
                                {...register("lettingfee", {
                                    required: true,
                                })}
                            />
                            {errors.lettingfee && errors.lettingfee.type === "required" && (
                                <small className="text-danger">Letting Fee is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Property Managment Fees Percentage</label>
                            <input
                                type="number"
                                class="form-control"
                                name="propertymanagementfeepercentage"
                                {...register("propertymanagementfeepercentage", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                            />
                            {errors.propertymanagementfeepercentage && errors.propertymanagementfeepercentage.type === "required" && (
                                <small className="text-danger">Property Managment Fees Percentage is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Total Property Managment Fee</label>
                            <input
                                type="number"
                                class="form-control"
                                name="totalpropertymanagementfee "
                                {...register("totalpropertymanagementfee", {
                                    required: true,
                                })}
                            />
                            {errors.totalpropertymanagementfee && errors.totalpropertymanagementfee.type === "required" && (
                                <small className="text-danger"> Total Property Managment Fees  is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Loan Amount</label>
                            <input
                                type="number"
                                class="form-control"
                                name="loanamount"
                                {...register("loanamount", {
                                    required: true,
                                })}
                            />
                            {errors.loanamount && errors.loanamount.type === "required" && (
                                <small className="text-danger">Loan Amount is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Bank Name </label>
                            <input
                                type="text"
                                class="form-control"
                                name="purchasebank"
                                {...register("purchasebank", {
                                    required: true,
                                })}
                            />
                            {errors.purchasebank && errors.purchasebank.type === "required" && (
                                <small className="text-danger">Bank Name is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Select Loan Type</label>
                            <select {...register("loantype", {
                                required: true,
                            })} class="form-control" name="loantype" >
                                <option>Select loan type</option>
                                <option value="P&I">P&I</option>
                                <option value="Interest">Interest</option>
                            </select>
                            {errors.loantype && errors.loantype.type === "required" && (
                                <small className="text-danger">Loan Type is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Interest Fixed</label>
                            <input
                                type="number"
                                class="form-control"
                                name="interestfixed"
                                {...register("interestfixed", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                            />
                            {errors.interestfixed && errors.interestfixed.type === "required" && (
                                <small className="text-danger">Interest Fixed is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Percentage Fixed</label>
                            <input
                                type="number"
                                class="form-control"
                                name="percentagefixed"
                                {...register("percentagefixed", {
                                    required: true,
                                })}
                            />
                            {errors.percentagefixed && errors.percentagefixed.type === "required" && (
                                <small className="text-danger">Percentage Fixed is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Term Fixed</label>
                            <input
                                type="number"
                                class="form-control"
                                name="termfixed"
                                {...register("termfixed", {
                                    required: true,
                                })}
                            />
                            {errors.termfixed && errors.termfixed.type === "required" && (
                                <small className="text-danger">Term Fixed is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Interest Variable</label>
                            <input
                                type="number"
                                class="form-control"
                                name="interestvariable"
                                {...register("interestvariable", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                            />
                            {errors.interestvariable && errors.interestvariable.type === "required" && (
                                <small className="text-danger">Interest Variable is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Percentage Variable</label>
                            <input
                                type="number"
                                class="form-control"
                                name="percentagevariable"
                                {...register("percentagevariable", {
                                    required: true,
                                })}
                            />
                            {errors.percentagevariable && errors.percentagevariable.type === "required" && (
                                <small className="text-danger">Percentage Variable is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Term Variable</label>
                            <input
                                type="number"
                                class="form-control"
                                name="termvariable"
                                {...register("termvariable", {
                                    required: true,
                                })}
                            />
                            {errors.termvariable && errors.termvariable.type === "required" && (
                                <small className="text-danger">Term Variable is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Building insurance fee</label>
                            <input
                                type="number"
                                class="form-control"
                                name="buildinginsurancefee"
                                {...register("buildinginsurancefee", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                                
                            />
                            {errors.buildinginsurancefee && errors.buildinginsurancefee.type === "required" && (
                                <small className="text-danger">Building insurance fee is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Water Rates</label>
                            <input
                                type="number"
                                class="form-control"
                                name="waterrates"
                                {...register("waterrates", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                            />
                            {errors.waterrates && errors.waterrates.type === "required" && (
                                <small className="text-danger">Water Rates is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Council Rates</label>
                            <input
                                type="number"
                                class="form-control"
                                name="councilrates"
                                {...register("councilrates", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                            />
                            {errors.councilrates && errors.councilrates.type === "required" && (
                                <small className="text-danger">Council Rates is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Yard Maintainance</label>
                            <input
                                type="number"
                                class="form-control"
                                name="yardmaintenance"
                                {...register("yardmaintenance", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                            />
                            {errors.yardmaintenance && errors.yardmaintenance.type === "required" && (
                                <small className="text-danger">Yard Maintainance is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label> Maintainance Allownace</label>
                            <input
                                type="number"
                                class="form-control"
                                name="maintainanceallownace"
                                {...register("maintainanceallownace", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                            />
                            {errors.maintainanceallownace && errors.maintainanceallownace.type === "required" && (
                                <small className="text-danger">Maintainance Allownace is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label> Forcast Annual Cash Expense</label>
                            <input
                                type="number"
                                class="form-control"
                                name="forcastannualcashexpense"
                                {...register("forcastannualcashexpense", {
                                    required: true,
                                })}
                            />
                            {errors.forcastannualcashexpense && errors.forcastannualcashexpense.type === "required" && (
                                <small className="text-danger">Forcast Annual Cash Expense is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Land Tax</label>
                            <input
                                type="number"
                                class="form-control"
                                name="landtax"
                                {...register("landtax", {
                                    required: true,
                                })}
                            />
                            {errors.landtax && errors.landtax.type === "required" && (
                                <small className="text-danger">Land Tax is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Principal Payment</label>
                            <input
                                type="number"
                                class="form-control"
                                name="principalpayment"
                                {...register("principalpayment", {
                                    required: true,
                                })}
                            />
                            {errors.principalpayment && errors.principalpayment.type === "required" && (
                                <small className="text-danger">Principal Payment is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Forcast Annual Non Deduct Expense</label>
                            <input
                                type="number"
                                class="form-control"
                                name="forecastannualnondeductexpense"
                                {...register("forecastannualnondeductexpense", {
                                    required: true,
                               })}
                               onKeyUp={rentalpwfunction}
                               
                            />
                            {errors.forecastannualnondeductexpense && errors.forecastannualnondeductexpense.type === "required" && (
                                <small className="text-danger">Forcast Annual Non Deduct Expense is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Potential Deduction</label>
                            <input
                                type="number"
                                class="form-control"
                                name="potentialdeductions"
                                {...register("potentialdeductions", {
                                    required: true,
                                })}
                            />
                            {errors.potentialdeductions && errors.potentialdeductions.type === "required" && (
                                <small className="text-danger">Potential Deduction is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Potential Annual Net Cash Flow</label>
                            <input
                                type="number"
                                class="form-control"
                                name="potentialann
                                ualnetcashoutflow
                                "
                                {...register("potentialannualnetcashoutflow", {
                                    required: true,
                                })}
                            />
                            {errors.potentialannualnetcashoutflow && errors.potentialannualnetcashoutflow.type === "required" && (
                                <small className="text-danger">Potential Annual Net Cash Flow is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Property Value</label>
                            <input
                                type="number"
                                class="form-control"
                                name="propertyvalue"
                                {...register("propertyvalue", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                            />
                            {errors.propertyvalue && errors.propertyvalue.type === "required" && (
                                <small className="text-danger">Property Value is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Fixed loan Amount</label>
                            <input
                                type="number"
                                class="form-control"
                                name="fixedloanamount"
                                {...register("fixedloanamount", {
                                    required: true,

                                })}
                            />
                            {errors.fixedloanamount && errors.fixedloanamount.type === "required" && (
                                <small className="text-danger">Fixed loan Amount is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Variable Amount</label>
                            <input
                                type="number"
                                class="form-control"
                                name="variableloanamount"
                                {...register("variableloanamount", {
                                    required: true,
                                })}
                            />
                            {errors.variableloanamount && errors.variableloanamount.type === "required" && (
                                <small className="text-danger">Variable Amount is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Total Loan Amount</label>
                            <input
                                type="number"
                                class="form-control"
                                name="totalloanamount"
                                {...register("totalloanamount", {
                                    required: true,
                                })}
                                onKeyUp={rentalpwfunction}
                            />
                            {errors.totalloanamount && errors.totalloanamount.type === "required" && (
                                <small className="text-danger">Total loan Amount is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Equity</label>
                            <input
                                type="number"
                                class="form-control"
                                name="equity"
                                {...register("equity", {
                                    required: true,
                                })}
                            />
                            {errors.equity && errors.equity.type === "required" && (
                                <small className="text-danger">Equity is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Fixed Principal Amount</label>
                            <input
                                type="number"
                                class="form-control"
                                name="fixedprincipalpayment"
                                {...register("fixedprincipalpayment", {
                                    required: true,
                                })}
                            />
                            {errors.fixedprincipalpayment && errors.fixedprincipalpayment.type === "required" && (
                                <small className="text-danger">Fixed Principal Amount is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Fixed Principal Payment Cumulative</label>
                            <input
                                type="number"
                                class="form-control"
                                name="otheragentfee"
                                {...register("fixedprincipalpaymentcumulative", {
                                    required: true,

                                })}
                            />
                            {errors.fixedprincipalpaymentcumulative && errors.fixedprincipalpaymentcumulative.type === "required" && (
                                <small className="text-danger">Fixed Principal Payment Cumulative is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Variable Principal Payment</label>
                            <input
                                type="number"
                                class="form-control"
                                name="variableprincipalpayment"
                                {...register("variableprincipalpayment", {
                                    required: true,

                                })}
                            />
                            {errors.variableprincipalpayment && errors.variableprincipalpayment.type === "required" && (
                                <small className="text-danger">Variable Principal Payment is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Variable Principal Payment Cumulative</label>
                            <input
                                type="number"
                                class="form-control"
                                name="variableprincipalpaymentcumulative
              "
                                {...register("variableprincipalpaymentcumulative", {
                                    required: true,

                                })}
                            />
                            {errors.variableprincipalpaymentcumulative && errors.variableprincipalpaymentcumulative.type === "required" && (
                                <small className="text-danger">Variable Principal Payment Cumulative is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Total Principal Payment</label>
                            <input
                                type="number"
                                class="form-control"
                                name="totalprincipalpayment"
                                {...register("totalprincipalpayment", {
                                    required: true,

                                })}
                            />
                            {errors.totalprincipalpayment && errors.totalprincipalpayment.type === "required" && (
                                <small className="text-danger">Total Principal Payment is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Total Principal Payment Cumulative</label>
                            <input
                                type='checkbox'
                                name="totalprincipalpaymentcumulative"
                                {...register("totalprincipalpaymentcumulative", {

                                    required: true,
                                })}

                            />
                            {errors.totalprincipalpayment && errors.totalprincipalpayment.type === "required" && (
                                <small className="text-danger">Total Principal Payment Cumulative is required.</small>
                            )}


                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Gross Yield</label>
                            <input
                                type="number"
                                class="form-control"

                                name="grossyield"
                                {...register("grossyield", {
                                    required: true,

                                })}
                            />
                            {errors.grossyield && errors.grossyield.type === "required" && (
                                <small className="text-danger">Gross Yield is required.</small>
                            )}

                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Cash Flows</label>
                            <input
                                type="number"
                                class="form-control"

                                name="cashoutflows"
                                {...register("cashoutflows", {
                                    required: true,

                                })}
                            />
                            {errors.cashoutflows && errors.cashoutflows.type === "required" && (
                                <small className="text-danger">Cash Flows is required.</small>
                            )}

                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Total Interest Expense</label>
                            <input
                                type="number"
                                class="form-control"

                                name="totalinterestexpense"
                                {...register("totalinterestexpense", {
                                    required: true,

                                })}
                            />
                            {errors.totalinterestexpense && errors.totalinterestexpense.type === "required" && (
                                <small className="text-danger">Total Interest Expense is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Net Annual Cashflow</label>
                            <input
                                type="number"
                                class="form-control"

                                name="netannualcashflow"
                                {...register("netannualcashflow", {
                                    required: true,

                                })}
                            />
                            {errors.netannualcashflow && errors.netannualcashflow.type === "required" && (
                                <small className="text-danger">Net Annual Cashflow is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Net Annual Cashflow Cummulative</label>
                            <input
                                type="number"
                                class="form-control"

                                name="netannualcashflowcumulative"
                                {...register("netannualcashflowcumulative", {
                                    required: true,

                                })}
                            />
                            {errors.netannualcashflowcumulative && errors.netannualcashflowcumulative.type === "required" && (
                                <small className="text-danger">Net Annual Cashflow Cummulative Units is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Net Yield </label>
                            <input
                                type="number"
                                class="form-control"

                                name="netyield"
                                {...register("netyield", {
                                    required: true,

                                })}
                            />
                            {errors.netyield && errors.netyield.type === "required" && (
                                <small className="text-danger">Net Yield is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Income Per Month </label>
                            <input
                                type="number"
                                class="form-control"

                                name="incomepermonth"
                                {...register("incomepermonth", {
                                    required: true,

                                })}
                            />
                            {errors.incomepermonth && errors.incomepermonth.type === "required" && (
                                <small className="text-danger">Income Per Month is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Income Per Week</label>
                            <input
                                type="number"
                                class="form-control"

                                name="incomeperweek"
                                {...register("incomeperweek", {
                                    required: true,

                                })}
                            />
                            {errors.incomeperweek && errors.incomeperweek.type === "required" && (
                                <small className="text-danger">Income Per Week is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Capital Growth</label>
                            <input
                                type='number'
                                class="form-control"
                                name="capitalgrowth"
                                {...register("capitalgrowth", {
                                    required: true,

                                })}

                            />
                            {errors.capitalgrowth && errors.capitalgrowth.type === "required" && (
                                <small className="text-danger">Capital Growth is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Capital Growth Cumulative</label>
                            <input
                                type='number'
                                name="capitalgrowthcumulative"
                                class="form-control"
                                {...register("capitalgrowthcumulative", {
                                    required: true,

                                })}

                            />
                            {errors.capitalgrowthcumulative && errors.capitalgrowthcumulative.type === "required" && (
                                <small className="text-danger">Capital Growth is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Total Performance </label>
                            <input
                                type='number'
                                class="form-control"
                                name="capitalgrowth"
                                {...register("totalperformance", {
                                    required: true,

                                })}

                            />
                            {errors.totalperformance && errors.totalperformance.type === "required" && (
                                <small className="text-danger">Total Performance is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Total Performance Principal</label>
                            <input
                                type='number'
                                class="form-control"
                                name="totalperformanceprincipal"
                                {...register("totalperformanceprincipal", {
                                    required: true,

                                })}

                            />
                            {errors.totalperformanceprincipal && errors.totalperformanceprincipal.type === "required" && (
                                <small className="text-danger">Total Performance Principal is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Cash on Cash Return Cumulative</label>
                            <input
                                type='number'
                                class="form-control"
                                name="cashoncashreturncumulative"
                                {...register("cashoncashreturncumulative", {
                                    required: true,

                                })}

                            />
                            {errors.cashoncashreturncumulative && errors.cashoncashreturncumulative.type === "required" && (
                                <small className="text-danger">Cash On Cash return Cumulative is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Return Invested Capital</label>
                            <input
                                type='number'
                                class="form-control"
                                name="returnoninvestedcapital"
                                {...register("returnoninvestedcapital", {
                                    required: true,

                                })}

                            />
                            {errors.returnoninvestedcapital && errors.returnoninvestedcapital.type === "required" && (
                                <small className="text-danger">Return Invested Capital is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group ">
                            <label>Renovation Cost</label>
                            <div className="input-group">

                                <input
                                    type="number"
                                    className="form-control"
                                    name="renovationcost"

                                    {...register("renovationcost", {
                                        required: true,
                                    })}
                                />
                            </div>

                            {errors.renovationcost && errors.renovationcost.type === "required" && (
                                <small className="text-danger">Renovation Cost is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group ">
                            <label>Renovation Equity Uplift</label>
                            <div className="input-group">

                                <input
                                    type="number"
                                    className="form-control"
                                    name="renovationequityuplift"

                                    {...register("renovationequityuplift", {
                                        required: true,
                                    })}
                                />
                            </div>

                            {errors.renovationequityuplift && errors.renovationequityuplift.type === "required" && (
                                <small className="text-danger">Renovation Equity Uplift is required.</small>
                            )}

                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Renovation Rental Uplift</label>
                            <input
                                type="number"
                                class="form-control"
                                name="renovationrentaluplift"

                                {...register("renovationrentaluplift", {
                                    required: true,
                                })}
                            />
                            {errors.renovationrentaluplift && errors.renovationrentaluplift.type === "required" && (
                                <small className="text-danger">Building insurance fee is required.</small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Sub Division Cost</label>
                            <input
                                type="number"
                                class="form-control"
                                name="subdivisioncost"

                                {...register("subdivisioncost", {
                                    required: true,

                                })}
                            />
                            {errors.subdivisioncost && errors.subdivisioncost.type === "required" && (
                                <small className="text-danger">Sub Division Cost is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Sub Division Equity Uplift  </label>
                            <input
                                type="number"
                                class="form-control"

                                name="subdivisionequityuplift"
                                {...register("subdivisionequityuplift", {
                                    required: true,

                                })}
                            />
                            {errors.subdivisionequityuplift && errors.subdivisionequityuplift.type === "required" && (
                                <small className="text-danger">Sub Division Equity Uplift is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Subdivision Rental Uplift </label>
                            <input
                                type="number"
                                class="form-control"

                                name="subdivisionrentaluplift"
                                {...register("subdivisionrentaluplift", {
                                    required: true,

                                })}
                            />
                            {errors.subdivisionrentaluplift && errors.subdivisionrentaluplift.type === "required" && (
                                <small className="text-danger">Subdivision Rental Uplift is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Granny Flat Cost</label>
                            <input
                                type="number"
                                class="form-control"

                                name="grannyflatcost"
                                {...register("grannyflatcost", {
                                    required: true,

                                })}
                            />
                            {errors.grannyflatcost && errors.grannyflatcost.type === "required" && (
                                <small className="text-danger">Granny Flat Cost is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Granny Flat Equity Uplift </label>
                            <input
                                type="number"
                                class="form-control"

                                name="grannyflatequityuplift"
                                {...register("grannyflatequityuplift", {
                                    required: true,

                                })}
                            />
                            {errors.grannyflatequityuplift && errors.grannyflatequityuplift.type === "required" && (
                                <small className="text-danger">Granny Flat Equity Uplift is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Granny Flat Rental Uplift fee </label>
                            <input
                                type="number"
                                class="form-control"

                                name="grannyflatrentaluplift"
                                {...register("grannyflatrentaluplift", {
                                    required: true,

                                })}
                            />
                            {errors.grannyflatrentaluplift && errors.grannyflatrentaluplift.type === "required" && (
                                <small className="text-danger"> Granny Flat Rental Uplift is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Total Uplift cost </label>
                            <input
                                type="number"
                                class="form-control"

                                name="totalupliftcost"
                                {...register("totalupliftcost", {
                                    required: true,

                                })}
                            />
                            {errors.totalupliftcost && errors.totalupliftcost.type === "required" && (
                                <small className="text-danger"> Total Uplift cost is required.</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Total Equity Uplift </label>
                            <input
                                type="number"
                                class="form-control"

                                name="totalequityuplift"
                                {...register("totalequityuplift", {
                                    required: true,

                                })}
                            />
                            {errors.totalequityuplift && errors.totalequityuplift.type === "required" && (
                                <small className="text-danger"> Total Equity Uplift .</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Total Rental Uplift </label>
                            <input
                                type="number"
                                class="form-control"

                                name="totalrentaluplift"
                                {...register("totalrentaluplift", {
                                    required: true,

                                })}
                            />
                            {errors.totalrentaluplift && errors.totalrentaluplift.type === "required" && (
                                <small className="text-danger"> Total Rental Uplift .</small>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>Advanced Details</label>
                            <input
                                type='checkbox'
                                name="advanceddetails"
                                {...register("advanceddetails", {


                                })}

                            />


                        </div>
                    </div>
                    {advanceddetailcheckbox && <>
                        <div className="row mt-5" >
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Price Growth</label>
                                    <input
                                        type="number"
                                        class="form-control"
                                        defaultValue={2}
                                        name="pricegrowth"
                                        {...register("pricegrowth", {
                                            required: true,

                                        })}
                                    />
                                    {errors.pricegrowth && errors.pricegrowth.type === "required" && (
                                        <small className="text-danger">Price Growth is required.</small>
                                    )}

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Rental Growth</label>
                                    <input
                                        type="number"
                                        class="form-control"
                                        defaultValue={2}
                                        name="rentalgrowth"
                                        {...register("rentalgrowth", {
                                            required: true,

                                        })}
                                    />
                                    {errors.rentalgrowth && errors.rentalgrowth.type === "required" && (
                                        <small className="text-danger">Rental Growth is required.</small>
                                    )}

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Inflation</label>
                                    <input
                                        type="number"
                                        class="form-control"
                                        defaultValue={2}
                                        name="inflation"
                                        {...register("inflation", {
                                            required: true,

                                        })}
                                    />
                                    {errors.inflation && errors.inflation.type === "required" && (
                                        <small className="text-danger">Inflation is required.</small>
                                    )}

                                </div>
                            </div>
                            </div>
                            </>
                            }


                    <button type="submit" class="btn btn-primary" >Submit</button>
                </div>
            </form>

        </div>
        <Footer />
    </>)
}

export default MaintainanceDetail