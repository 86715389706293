
import Propertycards from "../../component/Home/propertycards";
import NewsBlogs from "../../component/Home/blogs";
import HomeBanner from "../../component/Home/home-banner";
import FAQ from "../../component/Home/faq";
const { default: Footer } = require("../../component/Footer/footer")
const { default: Header } = require("../../component/Header/header")
const Home = () => {


return (<>
<Header />
<HomeBanner></HomeBanner>
<Propertycards/>
<FAQ></FAQ>
<NewsBlogs/>
<Footer />
</>)
}
export default Home