
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { ApiService } from "../../component/Services/apiservices";
import { BrowserView, MobileView } from "react-device-detect";
import moment from "moment";
import constant from '../../component/Services/constant';
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";

const Blogdetail = () => {
    let apiServices = new ApiService();
    const { slug } = useParams()
    const [blogdetail, setblogdetail] = useState({})
    const [isloading, setisloading] = useState(true)

    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getnewsblogslist() 
        }
        didMountRef.current = false;
    }, []);
    const getnewsblogslist = () => {
        const dataString={
            blog_slug:slug
        }
        apiServices.getnewsblogdetailrequest(dataString).then(res => {
            if (res?.data?.status == "success") {
                setblogdetail(res?.data?.newsblogs)
                setisloading(false);
            } else {
                setisloading(false)
            }
        }).catch((error)=>{
     
            setisloading(false);
        })
     }

  
    return (<>

        <BrowserView>
          <Header></Header>
            <div className="subheader">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/news-blogs">News & Blogs</Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {blogdetail.blog_title}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </div>
            <section class="section-gap-large">
                <div class="container">
                    <div class="row">
                        {isloading ? <>

                            <div class="col-lg-8">
                                <div class="listbog-details">
                                    <div class="thumbnail"><Skeleton height={'500px'}></Skeleton></div>
                                    <div class="content">
                                        <h1><Skeleton height={'20px'} width={'500px'}></Skeleton></h1>
                                        <ul class="blog-meta mb-3">
                                            <Skeleton height={'20px'} width={'200px'}></Skeleton>
                                            <Skeleton height={'20px'} width={'200px'}></Skeleton>
                                        </ul>
                                        <div class="desc">
                                            <div>
                                                <Skeleton height={'20px'} ></Skeleton>
                                                <Skeleton height={'20px'} ></Skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> : <>

                            <div class="col-lg-8">
                                <div class="listbog-details">
                                    <div class="thumbnail"><img src={blogdetail.blog_img ? constant.Image_Base_Url + '/' + blogdetail.blog_img : constant.default_img} alt="17091107154515.webp" /></div>
                                    <div class="content">
                                        <h1>{blogdetail?.blog_title}</h1>
                                        <ul class="blog-meta mb-3">
                                            <li>< i class="ri-calendar-fill"></i> {moment(blogdetail.createdAt).format('DD-MM-YYYY')}</li>
                                            {blogdetail.blog_written_by ? <li>< i class="ri-user-fill"></i>{blogdetail.blog_written_by}</li> : null}
                                        </ul>
                                        <div class="desc">
                                            <div>
                                                <p dangerouslySetInnerHTML={{ __html: blogdetail.blog_desc }}></p>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </section>
            <Footer></Footer>

        </BrowserView>
        <MobileView>
          
            <section class="section-gap-large">
                <div class="container">
                    <div class="row">
                        {isloading ? <>

                            <div class="col-lg-8">
                                <div class="listbog-details">
                                    <div class="thumbnail"><Skeleton height={'500px'}></Skeleton></div>
                                    <div class="content">
                                        <h1><Skeleton height={'20px'} width={'500px'}></Skeleton></h1>
                                        <ul class="blog-meta mb-3">
                                            <Skeleton height={'20px'} width={'200px'}></Skeleton>
                                            <Skeleton height={'20px'} width={'200px'}></Skeleton>
                                        </ul>
                                        <div class="desc">
                                            <div>
                                                <Skeleton height={'20px'} ></Skeleton>
                                                <Skeleton height={'20px'} ></Skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> : <>
                            <div class="col-lg-8">
                                <div class="listbog-details">
                                    <div class="thumbnail"><img src={blogdetail.blog_img ? constant.Image_Base_Url + '/' + blogdetail.blog_img : constant.default_img} alt="17091107154515.webp" /></div>
                                    <div class="content">
                                        <h1>{blogdetail?.blog_title}</h1>
                                        <ul class="blog-meta mb-3">
                                            <li>< i class="ri-calendar-fill"></i> {moment(blogdetail.createdAt).format('DD-MM-YYYY')}</li>
                                            {blogdetail.blog_written_by ? <li>< i class="ri-user-fill"></i>{blogdetail.blog_written_by}</li> : null}

                                        </ul>
                                        <div class="desc">
                                            <div>
                                                <p dangerouslySetInnerHTML={{ __html: blogdetail.blog_desc }}></p>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </section>

        </MobileView>

    </>)
}

export default Blogdetail