import React, { useState, useEffect, useRef } from "react";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useForm } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { useParams } from "react-router-dom";
import { Chart } from "react-google-charts"
import Loader from "../../component/utils/loader";
let apiServices = new ApiService()
const Graphs = () => {
    const [maintainncetableData, setmaintainncetableData] = useState([]);
    const [showloader, setshowloader] = useState(false)
    const didMountRef = useRef(true);
    const { id } = useParams()
    const {
        formState: { errors },
    } = useForm();
    useEffect(() => {

        if (didMountRef.current) {
            setshowloader(true)
            const datastring = {
                id: id
            };
            apiServices.getsavedmaintainancetabledatarequest(datastring).then((res) => {
                if (res?.data?.status == "success") {
                    const maintainancedata = res.data.maintainaancedata?.maintainance_table_data
                    const extractedObjects = maintainancedata.slice(0, 10);
                    setmaintainncetableData(extractedObjects);
                    setshowloader(false)
                }
                else {
                    setshowloader(false)
                }
            }).catch((error)=>{

            })
        }
        didMountRef.current = false;
        // rentalpwfunction()
    })

    const growthdata = [
        [
            "Element",
            "Growth",
            { role: "style" },
            {
                sourceColumn: 0,
                role: "annotation",
                type: "number",
                calc: "stringify",
            },
        ],
        // Create the data array outside JSX
        ...(maintainncetableData?.map((items, index) => [
            items?.currentYear,
            items?.gross_annual_rental,
            "#672d5f",
            null,
        ])),
    ];
    const data = [
        ["x", "Net Cashflow"],
        ...(maintainncetableData?.map((items, index) => [
            items?.currentYear,
            items?.net_annual_cash_flow,

        ])),
    ];

    const options = {
        hAxis: {
            title: "Year",
            gridlines: {
                count: 1,
            },
        },
        vAxis: {
            title: "net cashflow",
        },
        chartArea: { width: "50%", height: "70%" },
        // series: {
        //   1: { curveType: "function" },
        // },
    };
    const yeildoptions = {
        hAxis: {
            title: "year",
            gridlines: {
                count: 1,
            },
        },
        vAxis: {
            title: "yeilds",

        },
        chartArea: { width: "50%", height: "70%" },
        series: {

        },
    };
    const yielddata = [
        ["x", "gross yeild", "net yeild"],
        ...(maintainncetableData?.map((items, index) => [
            items?.currentYear,
            (items?.grossyield) * 100,
            (items?.netyeild) * 100,

        ])),
    ];
    const performancedata = [
        ["x", "total performance inc princi", "capital Growth (Cummulative)", "Net annual cashflow (cummulative)", "total principal payment (cummulative)"],
        ...(maintainncetableData?.map((items, index) => [
            items?.currentYear,
            (items?.total_performance_principal),
            (items?.capital_growth_annual_cummulative),
            (items?.net_annual_cashflow_cummulative),
            (items?.totalprincipalpaymentcummulative),

        ])),
    ];
    const performanceoption = {
        hAxis: {
            title: "year",
            gridlines: {
                count: 1,
            },
        },
        vAxis: {
            title: "yeilds",

        },
        chartArea: { width: "50%", height: "70%" },
    };

    const equityoption = {
        hAxis: {
            title: "year",
            gridlines: {
                count: 1,
            },
        },
        vAxis: {
            title: "Equity",

        },
        title: "Equity Projection",

        chartArea: { width: "50%", height: "70%" },
    }
    const equitydata = [
        ["x", "Equity Projection",],
        ...(maintainncetableData?.map((items, index) => [
            items?.currentYear,
            (items?.equity),


        ])),
    ];
    const grossrentaloption = {
        hAxis: {
            title: "year",
            gridlines: {
                count: 1,
            },
        },
        vAxis: {
            title: "Gross Rental Income",

        },
        title: "Gross Rental Income",

        chartArea: { width: "50%", height: "70%" },
    }
    const grossrentaldata = [
        ["x", "Gross Rental Income",],
        ...(maintainncetableData?.map((items, index) => [
            items?.currentYear,
            (items?.gross_annual_rental),


        ])),
    ];
    const grossincomeoption = {
        hAxis: {
            title: "year",
            gridlines: {
                count: 1,
            },
        },
        vAxis: {
            title: "Gross  Income",

        },
        title: "Gross  Income",

        chartArea: { width: "50%", height: "70%" },
    }
    const grossincomedata = [
        ["x", "Gross  Income",],
        ...(maintainncetableData?.map((items, index) => [
            items?.currentYear,
            (items?.gross_income),


        ])),
    ];
    return (<>
        <Header />
        <div className="container">
            <div className="row">
                {showloader && <Loader></Loader>}
                <div>
                    {/* <Chart
                        chartType="BarChart"
                        width="100%"
                        height="400px"
                        data={growthdata}
                        options={{
                            vAxis: {
                                title: "Growth",

                            },
                            hAxis: {
                                title: "Years",
                                gridlines: {
                                    count: 1,
                                },
                            },
                            orientation: 'horizontal',
                            width: 800,
                            height: 400,
                            bar: { groupWidth: "40%" },
                            legend: { position: "none" },
                        }}
                    />
                    <p>Gross Annual Rental Income</p> */}


                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="400px"
                        data={performancedata}
                        options={performanceoption}
                    />
                    <p>Total performance Graph</p>

                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="400px"
                        data={yielddata}
                        options={yeildoptions}
                    />
                    <p>Yeilds</p>

                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />
                    <p>Net annual cashflow</p>

                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="400px"
                        data={grossrentaldata}
                        options={grossrentaloption}
                    />
                    <p>Gross Annual Rental Income</p>
                    <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="400px"
                        data={grossincomedata}
                        options={grossincomeoption}
                    />
                    <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="400px"
                        data={equitydata}
                        options={equityoption}
                    />
                </div>
            </div>

        </div>
        <Footer />
    </>)
}

export default Graphs