import { BrowserView, MobileView } from "react-device-detect";
import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Services/apiservices";
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from "react-router-dom";
import constant from "../../Services/constant";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const FeaturedBlogs=()=>{
    let apiServices = new ApiService();
    const didMountRef = useRef(true);
    const [blogdata, setblogdata] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {
        if (didMountRef.current) {
            getnewsblogslist()
        }
        didMountRef.current = false;
    }, [])
    const skeletonArray = Array.from({ length: 3 });
    const navigate = useNavigate()
  
    const getnewsblogslist = () => {
        apiServices.getfeaturednewsbloglistrequest().then(res => {
            if (res?.data?.status == "success") {
                setblogdata(res?.data?.newsblogs)
                setloading(false);
            } else {
                setloading(false)
            }
        }).catch((error)=>{

            setloading(false);
        })
    }
return(<>

<BrowserView>
            {loading ? <>
                <section className="section-gap-md">
                    <div className="container">
                        <div className="section-title text-center">
                            <Skeleton width={300} />
                            <Skeleton width={400} />
                        </div>
                        <div className="row">
                            {skeletonArray.map((_, index) => {
                                return (<>
                                    <div className="col-lg-4" key={index}>
                                        <div className="edublog">
                                            <Skeleton height={300} />
                                            <div className="edublogContent">
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton width={'100px'} height={'40px'} />
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </div>
                </section>
            </> : <>
                {blogdata && blogdata.length > 0 ? <>
                    <section className="section-gap-md">
                        <div className="container">
                            <div className="section-title text-center">
                                <h2>Latest News & Update</h2>
                                <p>Without love, connecting rural India to the world would just be a dream.</p>
                                <a href="/news-blogs" className="explorebutton">View More</a>
                            </div>
                            <div className="row">
                                {blogdata.map((item, index) => {
                                    return (<>
                                        <div className="col-lg-4" key={item.index} >
                                            <div className="edublog">
                                                <figure className="edublogMedia" >
                                                    <a href={`/blog-detail/${item.blog_slug}`}>
                                                        <img src={item.blog_img ? constant.Image_Base_Url + '/' + item.blog_img : constant.default_img}></img>
                                                    </a>
                                                </figure>
                                                <div className="edublogContent">
                                                    <h5>{item?.blog_title}</h5>
                                                    <div className="shortdesc">{item.blog_short_desc}</div>
                                                    <button className="btn btn-primary btn-sm mt-20 " onClick={() => { navigate(`/blog-detail/${item.blog_slug}`) }}>
                                                        <span>Read More</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                                })}
                            </div>
                        </div>
                    </section>
                </> : null
                }
            </>}
        </BrowserView>
        <MobileView>
            {loading ? <>
                <section className="blogsection section-gap-medium">
                    <div className="container">
                        <div className="section-title section-center">
                            <Skeleton width={300} />
                            <Skeleton width={400} />
                        </div>
                        <div className="row">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={15}
                                navigation={false}
                                loop={true}
                                pagination={{ clickable: true }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                            >
                                {skeletonArray.map((_, index) => {
                                    return (<>
                                        <SwiperSlide key={index+Number(100)}>
                                            <div className="col-lg-4" key={index+Number(200)}>
                                                <div className="edublog">
                                                    <Skeleton height={300} />
                                                    <div className="edublogContent">
                                                        <Skeleton />
                                                        <Skeleton />
                                                        <Skeleton />
                                                        <Skeleton />
                                                        <Skeleton width={'100px'} height={'40px'} />
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </>)
                                })}
                            </Swiper>
                        </div>
                    </div>
                </section>
            </> : <>
                {blogdata && blogdata.length > 0 ? <>
                    <section className="blogsection section-gap-medium" >
                        <div className="container">
                            <div className="section-title section-center">
                                <h2>Latest News & Update</h2>
                                <p>Without love, connecting rural India to the world would just be a dream.</p>
                                <a href="/blogs" className="explorebutton">View More</a>
                            </div>
                            <div className="row">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={15}
                                    navigation={false}
                                    loop={true}
                                    pagination={{ clickable: true }}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                >
                                    {blogdata.map((item, index) => {
                                        return (<>
                                            <SwiperSlide key={item.blog_slug}>
                                                <div className="col-lg-4" key={item.blog_slug}>
                                                    <div className="edublog">
                                                        <figure className="edublogMedia">
                                                            <a href={`/blog-detail/${item.blog_slug}`}>
                                                                <img src={item.blog_img ? constant.Image_Base_Url + '/' + item.blog_img : constant.default_img}></img>
                                                            </a>
                                                        </figure>
                                                        <div className="edublogContent">
                                                            <h5>{item.blog_title}</h5>
                                                            <div >
                                                                {item.blog_short_desc}
                                                            </div>
                                                            <button className="btn-line small mt-10" onClick={() => { navigate(`/blog-detail/${item.blog_slug}`) }}>
                                                                Read More
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </>)
                                    })}
                                </Swiper> 
                            </div>
                        </div>
                    </section>
                </> : null 
                } 
            </>}
        </MobileView>
</>)
}

export default FeaturedBlogs