
import React, { useEffect, useRef, useState } from "react";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useNavigate } from "react-router-dom";

const MyProperty=()=>{
    const [show, setShow] = useState(false);
    const navigate =useNavigate()
  
    
    return(<>
    <Header></Header>
    <div className="container">
       <div>My Properties</div>

    </div>
 <Footer></Footer>
    
    </>)
}

export default MyProperty