import Accordion from 'react-bootstrap/Accordion';
import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Services/apiservices";
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from "react-router-dom";

const FAQ = () => {
    let apiServices = new ApiService();
    const didMountRef = useRef(true);
    const [faqData, setfaqData] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {
        if (didMountRef.current) {
            getFaqlistData()
        }
        didMountRef.current = false;
    }, [])
    const skeletonArray = Array.from({ length: 3 });
    const getFaqlistData = () => {
        apiServices.getallfaqlistrequest().then(res => {
            if (res?.data?.status == "success") {
                setfaqData(res?.data?.FaqData)
                setloading(false);
            } else {
                setloading(false)
            }
        }).catch((error) => {

            setloading(false);
        })
    }

    return (<>
            {loading ? <>
                <section className="section-gap-md">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2><Skeleton></Skeleton></h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <Accordion defaultActiveKey="0">
                                {skeletonArray && skeletonArray.map((items, index) => {
                                        return(<>
                                           <Accordion.Item eventKey={index.toString()} key={index}>
                                            <Accordion.Header><Skeleton></Skeleton></Accordion.Header>
                                            <Accordion.Body>
                                                <Skeleton></Skeleton>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        
                                        </>)
                                       
                                    })}
                                </Accordion>
                            </div>

                        </div>
                    </div>
                </section>
            </> : <>
                {faqData.length > 0 ? 
                
                <section className="section-gap-md">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>Frequently Asked Questions (FAQ)</h2>

                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <Accordion defaultActiveKey="0">
                                    {faqData && faqData.map((items, index) => {
                                        return(<>
                                         <Accordion.Item eventKey={index.toString()} key={index}>
                                            <Accordion.Header>{items?.faq_title}</Accordion.Header>
                                            <Accordion.Body>
                                                {items?.faq_description}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        
                                        </>)
                                       
                                    })}
                                </Accordion>
                               
                            </div>
                        </div>
                    </div>
                </section> : null}
            </>}
       
       
    </>)
}

export default FAQ